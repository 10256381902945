<template>
  <div class="main">
      <div class="bread">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item v-for="(item,index) in fromFather" :key="index" :to="{path:'/'+item.path}">{{item.name}}</el-breadcrumb-item>
          </el-breadcrumb>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        fromFather:{
            type:Array,
            default:()=>{
                return []
            }
        }
    },
}
</script>

<style scoped lang="scss">
.bread{
    height: 30px;
    line-height: 30px;
    width: 400px;
}
</style>