<template>
  <div class="main-content">
      <div class="contetn-view">
        <!-- 面包屑 -->
        <div class="bread">
          <bread :fromFather="dataPath"></bread>
        </div>
        <!-- 内容 -->
        <div class="content">
          <div class="view">
            <div class="name">{{itemData.noticeTitle}}</div>
            <div class="time">
              <div class="time-title">{{itemData.press+'  '+itemData.createTime}}</div>
              <div class="title-img" @click="collectionMethod()">
                <div>
                  <img src="../../../assets/news/collection-no.png" alt="">
                </div>
				<div v-show="itemData.isCollection == 1">已收藏</div>
                <div v-show="itemData.isCollection == 0">收藏</div>
              </div>
            </div>
            <div class="one" v-html="itemData.noticeContent"></div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import {Message} from 'element-ui'
import bread from '../../../components/tools/bread-box'
export default {
	components:{bread},
	data() {
		return {
			itemData:{},
			newID:'',
			dataPath:[
				{
				  path:'news',
				  name:'新闻',
				},{
				  path:'newDetails',
				  name:'新闻详情',
				}
			]
		}
	},
	created(){
		this.newID = this.$route.query.newID
		this.$store.commit('changClick',1)
		var skin = sessionStorage.getItem('Skin')
		var zskin = sessionStorage.getItem('ZSkin')
		if (skin) {
			this.$store.commit('changSkin',skin)
		}
		if (zskin) {
			this.$store.commit('changZSkin',zskin)
		}
		this.getNoticeInfo()
	},
	methods:{
		/**新闻详情**/ 
		getNoticeInfo(){
			var that = this
			that.$api.getNoticeInfo({
				params:{
					id:that.newID
				}
			}).then(res=>{
				if (res.data.code == 0) {
					that.itemData = res.data.data
				}else{
					that.itemData = {}
				}
			})
		},
		/**收藏/取消收藏**/ 
		getNewCollection(index){
			var that = this
			that.$api.getNewCollection({
				articleId:that.itemData.noticeId
			}).then(res=>{
				if (res.data.code == 0) {
					if(this.itemData.isCollection == 0){
						that.itemData.isCollection = 1
						Message({
							showClose: true,
							message: '收藏成功！',
							type: 'success'
						})
					}else{
						that.itemData.isCollection = 0
						Message({
							showClose: true,
							message: '取消收藏成功！',
							type: 'success'
						})
					}
				}else{
					if(this.itemData.isCollection == 0){
						that.itemData.isCollection = 0
						Message({
							showClose: true,
							message: '收藏失败！',
							type: 'error'
						})
					}else{
						that.itemData.isCollection = 1
						Message({
							showClose: true,
							message: '取消收藏失败！',
							type: 'error'
						})
					}
				}
			})
		},
		/**收藏按钮**/
		collectionMethod(){
			var token = sessionStorage.getItem('token')
			if(token){
				this.getNewCollection()
			}else{
				Message({
					showClose: true,
					message: '请登陆后再收藏！',
					type: 'error'
				})
			}
		}
	}
}
</script>

<style scoped lang="scss">
.main-content{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .contetn-view{
    width: 1200px;
    .bread{
      margin-top: 20px;
    }
    .content{
      display: flex;
      flex-direction: column;
      align-items: center;
      .view{
        width: 996px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 200px;
        .name{
          font-size: 24px;
          line-height: 34px;
          height: 40px;
          color: #333333;
        }
        .time{
          display: flex;
          justify-items: center;
          align-items: center;
          height: 20px;
          padding-top: 30px;
          .time-title{
            font-size: 15px;
            line-height: 20px;
            text-align: center;
            color: #333333;
          }
          .title-img{
            cursor: pointer;
            display: flex;
            justify-items: center;
            align-items: center;
            margin-left: 50px;
            font-size: 15px;
            line-height: 20px;
            color: #333333;
            > div img{
              padding-right: 10px;
            }
          }
        }
        .one{
          font-size: 15px;
          line-height: 20px;
          margin-top: 60px;
          color: #333333;
        }
        .two{
          margin-top: 40px;
        }
        .three{
          font-size: 15px;
          line-height: 20px;
          text-align: justify;
          margin-top: 40px;
          color: #333333;

        }
      }
    }
  }

}
</style>